









































import { VueEditor } from 'vue2-editor';
import { defineComponent, ref } from '@vue/composition-api';
import { clone } from 'ramda';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ContractTemplate } from '../interfaces';

export default defineComponent({
    name: 'EditContractTemplate',
    components: {
        ValidationObserver,
        ValidationProvider,
        VueEditor,
    },
    props: {
        contractTemplate: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const content = ref<any>(null);
        const isNew = ref<boolean>(true);

        const toolbar = [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean'], // remove formatting button
        ];

        const updatingContractTemplate = ref<ContractTemplate>({
            id: 0,
            name: undefined,
            details: undefined,
        });

        if (props.contractTemplate) {
            updatingContractTemplate.value = clone(props.contractTemplate) as ContractTemplate;
            isNew.value = false;
        }

        const validateAndProceed = async () => {
            const valid = await content.value.validate();
            if (valid) {
                if (isNew.value) emit('create', updatingContractTemplate.value);
                else emit('update', updatingContractTemplate.value);
            }
        };

        return {
            content,
            validateAndProceed,
            updatingContractTemplate,
            toolbar,
        };
    },
});
