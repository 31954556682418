


























































































































































































































































































import { Scrollbar, TwButton } from '@/app/components';
import { useAxios, useFeatureFlags, useFilters, useQuery, useResult } from '@/app/composable';
import store from '@/app/store';
import {
    alphanumericWithSpacesValidator,
    managerConfirmationValidator,
    maxLengthValidator,
    minLengthValidator,
    regexValidator,
    requiredValidator,
    termsAndConditionsValidator,
    urlValidator,
} from '@/app/validators';
import Auth from '@/modules/auth/api/auth';
import Keycloak from '@/modules/auth/api/keycloak';
import { OrganizationsAPI } from '@/modules/organization/api';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { Route } from 'vue-router';
import { CitySelect } from '../components';
import GET_COUNTRIES from '../graphql/getCountries.graphql';
import GET_TYPES from '../graphql/getTypes.graphql';
import { CreateOrganization } from '../types';

extend('required', requiredValidator);
extend('termsAndConditionsIsRequired', termsAndConditionsValidator);
extend('managerConfirmationIsRequired', managerConfirmationValidator);
extend('regex', regexValidator);
extend('email', email);
extend('alpha_num_spaces', alphanumericWithSpacesValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('url', urlValidator);

export default defineComponent({
    name: 'OrganizationRegistration',
    metaInfo: {
        title: 'Register Organisation',
    },
    components: { TwButton, ValidationProvider, ValidationObserver, Scrollbar, CitySelect },
    setup(props, { root }) {
        const { exec, loading } = useAxios(true);
        const { capitalise } = useFilters();

        const { result: resultCountries } = useQuery(GET_COUNTRIES, {}, { fetchPolicy: 'no-cache' });
        const countries = useResult(resultCountries, [], (data: any) => data.countries);
        const sortedCountries = computed(() => countries.value.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const { result: resultTypes } = useQuery(GET_TYPES, {}, { fetchPolicy: 'no-cache' });
        const types = useResult(resultTypes, [], (data: any) => data.organisationTypes);
        const sortedTypes = computed(() => types.value.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        const typeGroups = computed(() =>
            sortedTypes.value.reduce((acc: any, type: any) => {
                const domain = capitalise(type.domain);
                if (!acc[domain]) acc[domain] = [];
                acc[domain].push({ value: type.id, label: type.name });
                return acc;
            }, {}),
        );

        const message = ref<any>(null);
        const showMessage = ref(false);
        const enableTermsAndConditions = false; // turn to true if you want to show terms and conditions component
        const managerConfirmation = false;

        const organizationDataTemplate = ref<CreateOrganization>({
            legalName: '',
            businessName: '',
            address: '',
            postalCode: '',
            website: '',
            description: '',
            cityId: null,
            countryId: null,
            continentId: null,
            typeId: null,
        });

        const continentIdOfSelectedCountry = computed<number>(() =>
            organizationDataTemplate.value.countryId
                ? sortedCountries.value.filter((c: any) => c.id === organizationDataTemplate.value.countryId)[0]
                      .continent.id
                : null,
        );

        if (enableTermsAndConditions) {
            // eslint-disable-next-line dot-notation
            organizationDataTemplate.value['termsAndConditions'] = false;
        }

        const registerOrganization = () => {
            organizationDataTemplate.value.continentId = continentIdOfSelectedCountry.value;
            exec(OrganizationsAPI.registerOrganization(organizationDataTemplate.value))
                .then(async () => {
                    root.$router.push({ name: 'unverified-organization' }); // redirect to Your organization is pending approval Page.
                })
                .catch((e: any) => {
                    if (e.response && e.response.data && e.response.data.message) {
                        switch (e.response.data.message) {
                            case 'Unauthorized':
                                (root as any).$toastr.e('Unauthorised action', 'Error');
                                break;
                            case 'Registration is closed':
                                message.value = 'Registration is closed';
                                showMessage.value = true;
                                break;
                            case 'User is already in an organisation.':
                                (root as any).$toastr.e('User is already in an organisation.', 'Error');
                                break;
                            default:
                                message.value =
                                    e.response.data.message && e.response.data.message[0].includes('website')
                                        ? 'Website must be a valid URL'
                                        : 'Legal Name already exists.';
                                showMessage.value = true;
                        }
                    }
                });
        };

        const isRegistrationOpen = computed(() => message.value !== 'Registration is closed');

        const legalNameAlreadyExists = computed(
            () => isRegistrationOpen.value && message.value && message.value.includes('Legal'),
        );
        const websiteIsNotValid = computed(
            () => isRegistrationOpen.value && message.value && message.value.includes('Website'),
        );

        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            try {
                await Auth.logout();
                await Keycloak.logout();
                store.commit.auth.CLEAR_USER();
                store.commit.sharing.CLEAR_NETWORK();
                store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
                root.$router.push({ name: 'home' });
            } catch (e) {
                (root as any).$toastr.e(e, 'Logout failed');
            }
        };

        const changeCity = (cityId: number | null) => {
            organizationDataTemplate.value.cityId = cityId;
        };

        return {
            enableTermsAndConditions,
            loading,
            message,
            showMessage,
            registerOrganization,
            isRegistrationOpen,
            sortedCountries,
            organizationDataTemplate,
            sortedTypes,
            continentIdOfSelectedCountry,
            managerConfirmation,
            logout,
            legalNameAlreadyExists,
            websiteIsNotValid,
            typeGroups,
            changeCity,
        };
    },
    async beforeRouteEnter(to: Route, from: Route, next: any) {
        if (store.getters.auth.isAuthenticated) {
            return next();
        }
        try {
            const { isEnabled, areAnyEnabled } = useFeatureFlags();
            const { data: user } = await Auth.user();
            store.commit.auth.SET_USER(user);
            if (
                to.meta?.feature &&
                ((Array.isArray(to.meta.feature) && areAnyEnabled(to.meta.feature)) || !isEnabled(to.meta.feature))
            ) {
                return next({ name: '404' });
            }
            return next();
        } catch (e) {
            return Keycloak.login(to);
        }
    },
});
