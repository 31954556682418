var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Scrollbar',{staticClass:"flex flex-col min-h-screen"},[_c('div',{staticClass:"mx-auto mt-4 md:mt-8 lg:mt-16"},[_c('img',{staticClass:"h-16",attrs:{"src":require("@/app/assets/img/logo-on-white.png"),"alt":"SYNERGIES"}}),_c('button',{staticClass:"w-full px-4 mt-4 text-sm text-center ring-white text-neutral-700 hover:text-neutral-800",on:{"click":_vm.logout}},[_vm._v(" Sign out ")])]),_c('div',{staticClass:"flex max-w-xs mx-auto my-12 bg-white rounded-lg shadow-md md:max-w-lg lg:max-w-4xl"},[_c('div',{staticClass:"w-screen px-4 py-8 lg:p-8"},[_c('validation-observer',{ref:"registrationForm",staticClass:"flex flex-col space-y-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"flex flex-wrap md:flex-row"},[_c('div',{staticClass:"w-full px-4 mb-2 font-semibold uppercase text-primary-500"},[_vm._v(" Organisation Details ")]),_c('div',{staticClass:"w-full px-4"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"legalName"}},[_vm._v("Legal Name")]),_c('validation-provider',{attrs:{"vid":"legalName","name":"Legal Name","rules":{ required: true, max: 200, min: 2, regex: /^[a-zA-Z0-9 ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.legalName),expression:"organizationDataTemplate.legalName"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Legal Name"},domProps:{"value":(_vm.organizationDataTemplate.legalName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "legalName", $event.target.value)},function($event){_vm.legalNameAlreadyExists ? (_vm.showMessage = false) : null}]}}),(_vm.legalNameAlreadyExists)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"businessName"}},[_vm._v("Business Name")]),_c('validation-provider',{attrs:{"vid":"businessName","name":"Business Name","rules":{ required: true, max: 200, min: 2, regex: /^[a-zA-Z0-9 ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.businessName),expression:"organizationDataTemplate.businessName"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Business Name"},domProps:{"value":(_vm.organizationDataTemplate.businessName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "businessName", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"type"}},[_vm._v("Type")]),_c('validation-provider',{attrs:{"vid":"type","name":"Type","rules":"required"}},[_c('FormulateInput',{attrs:{"id":"type","type":"select","name":"Type","placeholder":"Select Type","inputClass":"block w-full form-select","errorClass":"mt-1 text-sm text-red-600","option-groups":_vm.typeGroups,"validation":"required","validationMessages":{
                                        required: 'Type is required',
                                    },"error-behavior":"value"},model:{value:(_vm.organizationDataTemplate.typeId),callback:function ($$v) {_vm.$set(_vm.organizationDataTemplate, "typeId", $$v)},expression:"organizationDataTemplate.typeId"}})],1)],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"website"}},[_vm._v("Website")]),_c('validation-provider',{attrs:{"vid":"website","name":"Website","rules":"required|max:128|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('div',{staticClass:"flex"},[_c('span',{staticClass:"inline-flex items-center px-3 border border-r-0 rounded-l text-neutral-500 bg-neutral-200 border-neutral-300"},[_vm._v(" http:// ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.website),expression:"organizationDataTemplate.website"}],staticClass:"w-full rounded-none rounded-r input-block",attrs:{"type":"text","name":"Website","placeholder":"www.example.com"},domProps:{"value":(_vm.organizationDataTemplate.website)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "website", $event.target.value)},function($event){_vm.websiteIsNotValid ? (_vm.showMessage = false) : null}]}})]),(_vm.websiteIsNotValid)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-wrap md:flex-row"},[_c('div',{staticClass:"w-full px-4 mt-4 font-semibold uppercase text-md text-primary-500"},[_vm._v(" Location Details ")]),_c('div',{staticClass:"w-full px-4 mt-2 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"address"}},[_vm._v("Address")]),_c('validation-provider',{attrs:{"vid":"address","name":"Address","rules":{ required: true, max: 200, min: 2, regex: /^[a-zA-Z0-9-, ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.address),expression:"organizationDataTemplate.address"}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Street Name and Number"},domProps:{"value":(_vm.organizationDataTemplate.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "address", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-2 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"postalCode"}},[_vm._v("Postal Code")]),_c('validation-provider',{attrs:{"vid":"postalCode","name":"Postal Code","rules":"required|max:10|min:2|alpha_num_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.organizationDataTemplate.postalCode),expression:"organizationDataTemplate.postalCode",modifiers:{"trim":true}}],staticClass:"input-block focus:ring",attrs:{"type":"text","placeholder":"Postal Code"},domProps:{"value":(_vm.organizationDataTemplate.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "postalCode", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"country"}},[_vm._v("Country")]),_c('validation-provider',{attrs:{"vid":"country","name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.countryId),expression:"organizationDataTemplate.countryId"}],staticClass:"block w-full text-md form-select text-neutral-500",class:{
                                        'text-neutral-900': _vm.organizationDataTemplate.countryId,
                                    },attrs:{"name":"Country"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.organizationDataTemplate, "countryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v("Select Country")]),_vm._l((_vm.sortedCountries),function(country){return _c('option',{key:country.id,domProps:{"value":country.id}},[_vm._v(" "+_vm._s(country.flag)+" "+_vm._s(country.name)+" ")])})],2),_c('div',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-4 mt-4 lg:w-1/2"},[_c('label',{staticClass:"text-sm font-semibold tracking-wide capitalised text-neutral-800",attrs:{"for":"city"}},[_vm._v("City")]),_c('CitySelect',{attrs:{"countryId":_vm.organizationDataTemplate.countryId,"value":_vm.organizationDataTemplate.cityId},on:{"change":_vm.changeCity}})],1)]),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"w-full px-4 mt-4"},[_c('div',{staticClass:"w-full mb-2 font-semibold uppercase text-primary-500"},[_vm._v("Comments")]),_c('validation-provider',{attrs:{"rules":"max:4096"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.organizationDataTemplate.description),expression:"organizationDataTemplate.description"}],staticClass:"block w-full mt-1 form-textarea",attrs:{"name":"Comments","rows":"3","placeholder":"A few words about your organisation"},domProps:{"value":(_vm.organizationDataTemplate.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organizationDataTemplate, "description", $event.target.value)}}}),_c('span',{staticClass:"mt-1 text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"flex items-center justify-between px-4 mt-4 lg:flex-row"},[_c('validation-provider',{attrs:{"name":"Manager Confirmation","vid":"managerConfirmation","rules":{ managerConfirmationIsRequired: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('div',{staticClass:"flex flex-row justify-between w-full space-x-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.managerConfirmation),expression:"managerConfirmation"}],staticClass:"flex w-4 mt-1",attrs:{"name":"Manager Confirmation","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.managerConfirmation)?_vm._i(_vm.managerConfirmation,null)>-1:(_vm.managerConfirmation)},on:{"change":function($event){var $$a=_vm.managerConfirmation,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.managerConfirmation=$$a.concat([$$v]))}else{$$i>-1&&(_vm.managerConfirmation=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.managerConfirmation=$$c}}}}),_c('div',{class:{ 'flex flex-col space-y-1': errors[0] }},[_c('label',{staticClass:"text-sm text-primary-600"},[_vm._v("By ticking this box, I confirm that I am the "),_c('p',{staticClass:"inline font-semibold underline"},[_vm._v("Manager")]),_vm._v(" of this organisation.")]),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('tw-button',{staticClass:"tracking-wide uppercase shadow",attrs:{"color":"primary","size":"sm","disabled":invalid || _vm.loading},on:{"click":_vm.registerOrganization}},[_vm._v(" Create Organisation ")])],1)],1),(!_vm.isRegistrationOpen)?_c('div',{staticClass:"flex self-center px-4 text-sm text-red-600 lg:self-end lg:flex-row"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }