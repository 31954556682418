








































import { FormBlock } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { WalletModal } from '@/modules/sharing/components';
import { OrganisationWallet, ContractTemplates } from '../components';
import store from '@/app/store';
import { useWallet } from '@/modules/sharing/composable';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { ContractTemplate } from '../interfaces';
import { WalletAction } from '@/modules/sharing/constants';
import { ContractTemplatesAPI } from '../api';

export default defineComponent({
    name: 'SharingOptions',
    components: {
        WalletModal,
        OrganisationWallet,
        ContractTemplates,
        FormBlock,
    },
    setup(props, { root }) {
        const { loading, exec } = useAxios(true);
        const { getWalletPrivateKey, getWalletBalanceInEthers } = useWallet();
        const wallet = ref<any>({ balance: null, privateKey: null, privateKeyToCopy: null });
        const showWalletModal = ref<WalletAction | null>(null);
        const user = computed(() => store.state.auth.user);
        const organisationContractTemplates = ref<ContractTemplate[]>([]);
        const isBlockchainEnabled = computed(() => store.getters.sharing.isBlockchainEnabled);

        const { formatDateTime } = useFilters();

        const walletUnlocked = async (unlockedWallet: any) => {
            wallet.value.privateKey = getWalletPrivateKey(unlockedWallet);
            wallet.value.balance = await getWalletBalanceInEthers(unlockedWallet);
        };

        const unlockWallet = () => {
            showWalletModal.value = WalletAction.Unlock;
        };

        const refetchContractTemplates = () => {
            exec(ContractTemplatesAPI.retrieveForOrganisation(user.value.organisationId))
                .then((res: any) => {
                    organisationContractTemplates.value = res.data.filter((template: any) => !!template.organisationId);
                })
                .catch(() => {
                    (root as any).$toastr.e('Contract Templates could not be retrieved due to an error', 'Error');
                });
        };

        refetchContractTemplates();

        return {
            loading,
            dayjs,
            formatDateTime,
            walletUnlocked,
            organisationContractTemplates,
            wallet,
            R,
            showWalletModal,
            isBlockchainEnabled,
            unlockWallet,
            refetchContractTemplates,
        };
    },
});
