













































































































































































































































































import { ConfirmModal, FormBlock, Pagination, TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import { CheckIcon, ChevronLeftIcon, PencilAltIcon, PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { OrganizationsAPI } from '../api';
import { OrganisationRole } from '../types';
import CitySelect from './CitySelect.vue';
import RoleModal from './RoleModal.vue';

export default defineComponent({
    name: 'OrganisationOverview',
    components: {
        ValidationObserver,
        ValidationProvider,
        FormBlock,
        CheckIcon,
        ChevronLeftIcon,
        TwButton,
        TrashIcon,
        PlusIcon,
        PencilAltIcon,
        RoleModal,
        ConfirmModal,
        Pagination,
        CitySelect,
    },
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        isManager: {
            type: Boolean,
            default: false,
        },
        organisationRoles: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const content = ref<any>(null);
        const { exec } = useAxios(true);
        const showRoleModal = ref<boolean>(false);
        const selectedRole = ref<any>(null);
        const roleToBeDeleted = ref<number>(0);
        const showDeleteRoleModal = ref<boolean>(false);

        // Roles pagination
        const rolesPageSize = 10;
        const rolesCurrentPage = ref<number>(1);
        const paginatedRoles = computed(() =>
            props.organisationRoles.slice(
                (rolesCurrentPage.value - 1) * rolesPageSize,
                rolesCurrentPage.value * rolesPageSize,
            ),
        );

        const org: any = ref<any>({
            legalName: props.organisation.legalName,
            businessName: props.organisation.businessName,
            type: props.organisation.type.name,
            website: props.organisation.website,
            description: props.organisation.description,
            location: {
                address: props.organisation.location.address,
                postalCode: props.organisation.location.postalCode,
                cityId: props.organisation.location.city.id,
            },
        });
        const clonedOrg = ref<any>(R.clone(org.value));

        const changesExist = computed(() => JSON.stringify(clonedOrg.value) !== JSON.stringify(org.value));

        const saveChanges = async () => {
            const payload = {
                businessName: org.value.businessName,
                description: org.value.description,
                address: org.value.location.address,
                postalCode: org.value.location.postalCode,
                cityId: Number(org.value.location.cityId),
                website: org.value.website,
            };

            try {
                await exec(OrganizationsAPI.update(props.organisation.id, payload));
                emit('refetch');
                (root as any).$toastr.Add({
                    title: 'Success',
                    msg: 'Organisation Details have been changed successfuly',
                    type: 'success',
                    timeout: 5000,
                    preventDuplicates: true,
                });
            } catch (e) {
                (root as any).$toastr.e('Editing organisation details failed', 'Error');
            }
        };

        const validateAndSave = async () => {
            const valid = await content.value.validate();
            if (valid) {
                saveChanges();
            }
        };

        const cancel = async () => {
            root.$router.go(-1);
        };

        const addRole = () => {
            selectedRole.value = null;
            showRoleModal.value = true;
        };

        const editRole = (role: OrganisationRole) => {
            selectedRole.value = role;
            showRoleModal.value = true;
        };

        const deleteRole = () => {
            exec(OrganizationsAPI.deleteRole(props.organisation.id, roleToBeDeleted.value))
                .then(() => {
                    emit('refetch-roles');
                    emit('refetch');
                    rolesCurrentPage.value = 1;
                    showDeleteRoleModal.value = false;
                    (root as any).$toastr.s('Role has been delete successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Deleting role failed', 'Error');
                });
        };

        const confirmDelete = (roleId: number) => {
            roleToBeDeleted.value = roleId;
            showDeleteRoleModal.value = true;
        };

        const changeCity = (cityId: number | null) => {
            org.value.location.cityId = cityId;
        };

        return {
            content,
            selectedRole,
            showRoleModal,
            org,
            changesExist,
            validateAndSave,
            cancel,
            addRole,
            editRole,
            deleteRole,
            confirmDelete,
            showDeleteRoleModal,
            rolesPageSize,
            rolesCurrentPage,
            paginatedRoles,
            changeCity,
        };
    },
});
