

































































































import { CopyToClipboardButton, SvgImage } from '@/app/components';
import store from '@/app/store';
import { WalletAction } from '@/modules/sharing/constants';
import { ChevronLeftIcon, LockOpenIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'OrganisationWallet',
    components: {
        SvgImage,
        ChevronLeftIcon,
        CopyToClipboardButton,
        LockOpenIcon,
    },
    props: {
        balance: {
            type: String,
            default: null,
        },
        privateKey: {
            type: String,
            default: null,
        },
    },
    setup(props, { root }) {
        const ethNetwork = computed(() => store.state.sharing.network);
        const hasWallet = computed(() => store.getters.organisation.hasWallet);

        const cancel = async () => {
            root.$router.go(-1);
        };

        return { ethNetwork, cancel, hasWallet, WalletAction };
    },
});
