


















































































import { ConfirmModal } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { PencilAltIcon, TrashIcon, TemplateIcon, CalendarIcon } from '@vue-hero-icons/outline';
import { defineComponent, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { ContractTemplatesAPI } from '../api';
import { ContractTemplate } from '../interfaces';
import { CreateOrUpdateContractTemplate } from '../types';
import EditContractTemplate from './EditContractTemplate.vue';

export default defineComponent({
    name: 'ContractTemplates',
    components: {
        TrashIcon,
        ConfirmModal,
        PencilAltIcon,
        TemplateIcon,
        CalendarIcon,
        EditContractTemplate,
    },
    props: {
        contractTemplates: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios(true);
        const { formatDateTime } = useFilters();
        const onEditMode = ref(false);
        const toBeDeleted = ref<number | null>(null);
        const showDeleteModal = ref(false);
        const selectedContractTemplate = ref<ContractTemplate | undefined>(undefined);

        const openContractTemplateEditor = () => {
            selectedContractTemplate.value = undefined;
            onEditMode.value = true;
        };

        const editContractTemplate = (contractTemplate: ContractTemplate) => {
            selectedContractTemplate.value = contractTemplate;
            onEditMode.value = true;
        };

        const deleteContractTemplate = () => {
            if (toBeDeleted.value)
                exec(ContractTemplatesAPI.delete(toBeDeleted.value))
                    .then(() => {
                        emit('refetch-contract-templates');
                        (root as any).$toastr.s('Contract Template has been deleted successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Deleting Contract Template failed', 'Error');
                    })
                    .finally(() => {
                        showDeleteModal.value = false;
                    })
                    .finally(() => emit('scroll-to-top'));
        };

        const createContractTemplate = (data: CreateOrUpdateContractTemplate) => {
            exec(ContractTemplatesAPI.create(data))
                .then(() => {
                    emit('refetch-contract-templates');
                    onEditMode.value = false;
                    (root as any).$toastr.s('Contract Template has been created successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Creating Contract Template failed due to an error', 'Error');
                })
                .finally(() => emit('scroll-to-top'));
        };

        const updateContractTemplate = (data: CreateOrUpdateContractTemplate) => {
            if (selectedContractTemplate.value)
                exec(ContractTemplatesAPI.update(selectedContractTemplate.value.id, data))
                    .then(() => {
                        emit('refetch-contract-templates');
                        onEditMode.value = false;
                        selectedContractTemplate.value = undefined;
                        (root as any).$toastr.s('Contract Template has been updated successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Updating Contract Template failed due to an error', 'Error');
                    })
                    .finally(() => emit('scroll-to-top'));
        };

        const confirmDelete = (id: number) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const cancelEditing = () => {
            onEditMode.value = false;
            selectedContractTemplate.value = undefined;
            emit('scroll-to-top');
        };

        return {
            selectedContractTemplate,
            editContractTemplate,
            openContractTemplateEditor,
            deleteContractTemplate,
            confirmDelete,
            showDeleteModal,
            dayjs,
            formatDateTime,
            onEditMode,
            createContractTemplate,
            updateContractTemplate,
            cancelEditing,
        };
    },
});
