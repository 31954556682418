


























import { AdvancedSelect } from '@/app/components';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { isNil } from 'ramda';
import { ValidationProvider } from 'vee-validate';
import { CitiesAPI, CountriesAPI } from '../api';
import { City } from '../interfaces';

export default defineComponent({
    name: 'CitySelect',
    components: { AdvancedSelect, ValidationProvider },
    props: {
        countryId: {
            type: [String, Number],
            default: null,
        },
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Select City',
        },
        placeholderSize: {
            type: String,
            default: 'text-base',
        },
    },

    setup(props, { emit }) {
        const payload = ref<{ page: number; pageSize: number; text: string }>({ page: 1, pageSize: 30, text: '' });
        const cities = ref<any>([]);
        const totalCities = ref<number>(0);
        const id = ref<number>(0);
        const selectedCity = ref<City | null>(null);
        const selectedItems = computed(() => (selectedCity.value ? [selectedCity.value] : []));

        const { exec, loading } = useAxios(true);

        const getCities = () => {
            exec(CountriesAPI.searchCitiesOfCountry(id.value, payload.value)).then((res: any) => {
                cities.value = res.data.results;
                totalCities.value = res.data.total;
                if (
                    !selectedCity.value &&
                    props.value &&
                    !cities.value.find((city: City) => city.id === Number(props.value))
                ) {
                    exec(CitiesAPI.retrieveCity(Number(props.value))).then((resCity: any) => {
                        selectedCity.value = resCity.data;
                    });
                }
            });
        };

        const search = (text: string, loadPage: number) => {
            payload.value.text = text;
            payload.value.page = loadPage;
            getCities();
        };

        const change = (cityId: number | null, city: City | null) => {
            selectedCity.value = city;
            emit('change', cityId);
        };

        watch(
            () => props.countryId,
            (countryId: string | number, oldCountryId: string | number | undefined) => {
                if (!isNil(countryId)) {
                    id.value = Number(countryId);
                    search('', 1);
                    if (oldCountryId && oldCountryId !== countryId) {
                        change(null, null);
                    }
                }
            },
            { immediate: true },
        );

        return { payload, id, cities, totalCities, search, loading, isNil, selectedItems, change };
    },
});
