


































import { useFeatureFlags } from '@/app/composable';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import Keycloak from '@/modules/auth/api/keycloak';
import { defineComponent } from '@vue/composition-api';
import { Route } from 'vue-router';

export default defineComponent({
    name: 'UnverifiedOrganization',
    metaInfo: {
        title: 'Unverified Organisation',
    },
    setup(props, { root }) {
        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            try {
                await Auth.logout();
                await Keycloak.logout();
                store.commit.auth.CLEAR_USER();
                store.commit.sharing.CLEAR_NETWORK();
                store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
                root.$router.push({ name: 'home' });
            } catch (e) {
                (root as any).$toastr.e(e, 'Logout failed');
            }
        };

        return { logout };
    },
    async beforeRouteEnter(to: Route, from: Route, next: any) {
        if (store.getters.auth.isAuthenticated) {
            return next();
        }
        try {
            const { isEnabled, areAnyEnabled } = useFeatureFlags();
            const { data: user } = await Auth.user();
            store.commit.auth.SET_USER(user);
            if (
                to.meta?.feature &&
                ((Array.isArray(to.meta.feature) && areAnyEnabled(to.meta.feature)) || !isEnabled(to.meta.feature))
            ) {
                return next({ name: '404' });
            }
            return next();
        } catch (e) {
            return Keycloak.login(to);
        }
    },
});
